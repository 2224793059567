
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as healtho4BxBimgN4KZW1Qc3F4JQbQlc6fevQRkKqOCsyP8t1UMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue?macro=true";
import { default as _91_46_46_46slug_93PdAOaCE_Kvi5YjWUp02q7oh_45wxpSDRlJRdstr194i8UMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue?macro=true";
import { default as guidesuoc6FMBRdQZkVCtKhhrZylNl2g4kC6JEVo_45eOvwBgqIMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue?macro=true";
import { default as port_45parkingkriieZSpXRULPjtt7hAv838B7b4xrZxZ4UjZUCyVP8YMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue?macro=true";
import { default as airport_45parkingdIckbAitjEttzhB5GycZ7zsmPLRoDee8IAyg1Y_uaisMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue?macro=true";
import { default as parking_45couponsTfCDS5eFsld6XZWZjpdGAl7iX2UI01JWYVyUgdWYJ8AMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue?macro=true";
import { default as _91result_93hZuRyjUQ_ueQ9gCbziUfp4k1QgyMpmFVKlJHGB_45Yig0Meta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue?macro=true";
import { default as airport_45shuttlesoq0zZnwlTtcCkMzaIHxkMdLbdBHXpjO0qosA3aFtWfkMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_9389Cid3kZPtBG_45NLCZeEMD1N0VuH8daL7tfOhoD_457j8IMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue?macro=true";
import { default as off_45airport_45parkingFBZbRvQCjolBWzIWUyDtNaFZNpw1oJ7r0fferaOTJYIMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93m9K_54guvBbXGeNXAwQWiynCkIasmQGf3zSqopzKaVUMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue?macro=true";
import { default as port_45hotels_45with_45parkingqKswQFRiYBYzDyKq_45mJOPGG4BKFGVI8EpnqDHpGzdJoMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue?macro=true";
import { default as airport_45hotels_45with_45parkingsn_Bi_rfNMLr4fDgwNoFQtlzjKEQfoN298XzCMTnfggMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue?macro=true";
import { default as _91code_93_45_91name_93_45_91IATA_93WQfr1AjhbzAAWJgicP2_AP0ARL7vZ8CRPcUbZV_iQswMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue?macro=true";
import { default as component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRAMeta } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRA } from "/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@8.57.1_ioredis@5_7bd7ee73d95dc7abddcd70c3f188bc96/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "health___en",
    path: "/health",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/health.vue")
  },
  {
    name: "slug___en",
    path: "/:slug(.*)*",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[...slug].vue")
  },
  {
    name: "IATA-guides___en",
    path: "/:IATA()/guides",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: "IATA-port-parking___en",
    path: "/:IATA()/port-parking",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-parking.vue")
  },
  {
    name: "IATA-airport-parking___en",
    path: "/:IATA()/airport-parking",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-parking.vue")
  },
  {
    name: "IATA-parking-coupons___en",
    path: "/:IATA()/parking-coupons",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/parking-coupons.vue")
  },
  {
    name: "flight-status-result___en",
    path: "/flight-status/:result()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/flight-status/[result].vue")
  },
  {
    name: "IATA-airport-shuttles___en",
    path: "/:IATA()/airport-shuttles",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-shuttles.vue")
  },
  {
    name: "hro-code-name-IATA___en",
    path: "/hro/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hro/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-off-airport-parking___en",
    path: "/:IATA()/off-airport-parking",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/off-airport-parking.vue")
  },
  {
    name: "parking-code-name-IATA___en",
    path: "/parking/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/parking/[code]-[name]-[IATA].vue")
  },
  {
    name: "IATA-port-hotels-with-parking___en",
    path: "/:IATA()/port-hotels-with-parking",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/port-hotels-with-parking.vue")
  },
  {
    name: "IATA-airport-hotels-with-parking___en",
    path: "/:IATA()/airport-hotels-with-parking",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/airport-hotels-with-parking.vue")
  },
  {
    name: "hotel-package-code-name-IATA___en",
    path: "/hotel-package/:code()-:name()-:IATA()",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/hotel-package/[code]-[name]-[IATA].vue")
  },
  {
    name: "Guides___en",
    path: "/:IATA([a-z]{3})",
    component: () => import("/codebuild/output/src194749792/src/bitbucket.org/magusa/cavu-workspaces/apps/apr/pages/[IATA]/guides.vue")
  },
  {
    name: component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRAMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubKUIO58VFWTn1xTNmi0kWGN9EHivzb93L9lUWDe_45VdRA
  }
]